<template>
  <div v-if="isLoading">
    <!-- Loading... -->
  </div>
  <div v-else>
    <div class="w-9/12 mx-auto">
      <p class="text-center py-4 text-sm">
        Connect suppliers to your company's functions
      </p>
      <div
        class="flex py-2 gap-6"
        v-for="(section, indexSection) in sections"
        :key="section.unit"
      >
        <div
          class="relative w-1/2 py-8 px-4 rounded border border-gray-300 shadow-card-function"
          style="height: max-content"
        >
          <ui-select
            :disabled="isLimited"
            placeholder="Function"
            :modelValue="section.unit"
            @update:modelValue="(newValue) => updateUnit(newValue, section)"
            :items="unitItems(section.unit)"
          />
          <div class="absolute left-full top-1/2 -mt-1 w-6 h-2 bg-gray-450">
            <!-- Line -->
          </div>
        </div>
        <div
          class="w-1/2 pt-3 pb-2.5 pl-4 pr-8 rounded border border-gray-300 shadow-card-function"
        >
          <div class="relative">
            <ui-select
              :disabled="isLimited"
              placeholder="Sub-function"
              :modelValue="section.subunits"
              @update:modelValue="(newValue) => (section.subunits = newValue)"
              multiple
              :class="{
                'opacity-60 pointer-events-none':
                  subUnitItems(section.unit).length === 0,
              }"
              :items="subUnitItems(section.unit)"
            />
            <div class="absolute left-full top-1/2 -mt-4 p-2">
              <ui-dialog :content-height="300" right sm>
                <template #activator>
                  <i class="h-8 w-8 block cursor-pointer">
                    <img
                      class="w-4"
                      src="@/assets/images/icons/more-horizontal.svg"
                      alt=""
                    />
                  </i>
                </template>
                <template #default>
                  <ul class="function-menu w-40">
                    <li
                      class="function-menu__option text-sm cursor-pointer"
                      :class="{ disabled: isLimited }"
                      @click="removeSection(indexSection)"
                    >
                      Remove Function
                    </li>
                    <li
                      class="function-menu__option text-sm mt-1 cursor-pointer"
                      :class="{ disabled: isLimited }"
                      @click="clearSubFunction(section)"
                    >
                      Remove Sub-functions
                    </li>
                  </ul>
                </template>
              </ui-dialog>
            </div>
          </div>

          <div class="mt-2 grid grid-cols-2 gap-y-2 gap-x-2.5">
            <div
              v-if="section.subunits.length === 0"
              class="border col-span-2 border-dotted rounded border-gray-300 flex w-full items-center justify-center"
            >
              <span class="py-2 px-3 leading-4 text-sm text-gray-600"
                >Select sub-functions</span
              >
            </div>
            <div
              v-for="(subunit, index) in section.subunits"
              :key="subunit"
              class="border rounded border-gray-300 flex w-full items-center justify-between"
              :class="{
                'col-span-2':
                  index === section.subunits.length - 1 &&
                  section.subunits.length % 2 === 1,
              }"
            >
              <span class="py-2 px-3 leading-4 text-sm">{{
                nameSubunitById(subunit)
              }}</span>
              <i
                class="block p-2.5 cursor-pointer"
                @click.stop="section.subunits.splice(index, 1)"
              >
                <img
                  width="12"
                  src="@/assets/images/icons/x.svg"
                  alt="Remove item"
                />
              </i>
            </div>
          </div>
        </div>
      </div>

      <div
        @click="addSection()"
        class="add-section-btn transition duration-300 ease-in-out group mt-4 text-center border-gray-300 bg-gray-200 rounded py-5.5 flex items-center justify-center hover:bg-blue-100 cursor-pointer"
        :class="{ disabled: isLimited }"
      >
        <svg
          class="mr-3"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6667 5.33329H6.66675V1.33329C6.66675 1.15648 6.59651 0.986912 6.47149 0.861888C6.34646 0.736864 6.17689 0.666626 6.00008 0.666626C5.82327 0.666626 5.6537 0.736864 5.52868 0.861888C5.40365 0.986912 5.33342 1.15648 5.33342 1.33329V5.33329H1.33341C1.1566 5.33329 0.987035 5.40353 0.86201 5.52855C0.736986 5.65358 0.666748 5.82315 0.666748 5.99996C0.666748 6.17677 0.736986 6.34634 0.86201 6.47136C0.987035 6.59639 1.1566 6.66663 1.33341 6.66663H5.33342V10.6666C5.33342 10.8434 5.40365 11.013 5.52868 11.138C5.6537 11.2631 5.82327 11.3333 6.00008 11.3333C6.17689 11.3333 6.34646 11.2631 6.47149 11.138C6.59651 11.013 6.66675 10.8434 6.66675 10.6666V6.66663H10.6667C10.8436 6.66663 11.0131 6.59639 11.1382 6.47136C11.2632 6.34634 11.3334 6.17677 11.3334 5.99996C11.3334 5.82315 11.2632 5.65358 11.1382 5.52855C11.0131 5.40353 10.8436 5.33329 10.6667 5.33329Z"
            fill="#2C83E9"
          />
        </svg>
        <span class="group-hover:text-blue-600 font-sm leading-4">
          Add function
        </span>
      </div>
    </div>

    <div class="flex justify-end">
      <ui-button
        v-if="isAnyChanges"
        oval
        :button-type="ButtonType.Blue"
        @click="saveChanges()"
      >
        Save
      </ui-button>
    </div>
  </div>
</template>

<script>
import { computed, inject, watch, onMounted, ref, reactive } from "vue";
import clientsApi from "@/services/api/clients-api";
import UiSelect from "@/components/ui/UiSelect.vue";
import UiButton from "@/components/ui/UiButton.vue";
import { ButtonType } from "@/enums/button";

import { useRoute } from "vue-router";
import { useStore } from "vuex";

import UiDialog from "@/components/ui/UiDialog.vue";

export default {
  components: {
    UiSelect,
    UiDialog,
    UiButton,
  },
  setup() {
    const supplier = inject("supplier");
    const supplierUpdate = inject("supplierUpdate");
    const businessUnits = ref(null);
    const isAnyChanges = ref(false);
    const route = useRoute();
    const store = useStore();

    const sections = reactive([
      // {
      //     unit: 436,
      //     subunits: [440],
      // },
    ]);

    onMounted(() => {
      fetchBusinessUnits();

      watch(isLoading, (isLoading) => {
        formatSelectedBusinessUnits(supplier.value.business_units);

        watch(
          sections,
          () => {
            isAnyChanges.value = true;
          },
          {
            deep: true,
          }
        );
      });
    });

    const isLoading = computed(
      () => !(supplier.value !== null && businessUnits.value !== null)
    );

    const unitItems = computed(
      () => (id) =>
        businessUnits.value?.reduce((accumulator, unit) => {
          if (!sections.some((item) => item.unit === unit.id && unit.id !== id))
            accumulator.push({ value: unit.id, label: unit.name });

          return accumulator;
        }, [])
    );

    function removeSection(index) {
      sections.splice(index, 1);
    }

    function clearSubFunction(section) {
      section.subunits = [];
    }

    function addSection() {
      sections.push({
        unit: null,
        subunits: [],
      });
    }

    function updateUnit(newValue, section) {
      if (newValue === section.unit) return;
      section.unit = newValue;
      section.subunits = [];
    }

    const isLimited = computed(() => store.getters.isLimited);
    const nameSubunitById = computed(
      () => (id) =>
        businessUnits.value
          .map((unit) => unit.subunits)
          .flat()
          .find((subUnit) => subUnit.id === id).name
    );
    const subUnitItems = computed(() => {
      return (id) => {
        return id
          ? businessUnits.value
              ?.find((unit) => unit.id === id)
              .subunits.map((unit) => {
                return { value: unit.id, label: unit.name };
              })
          : [];
      };
    });

    async function fetchBusinessUnits() {
      businessUnits.value = await clientsApi.fetchBusinessUnits();
    }

    async function saveChanges() {
      // Need validation
      isAnyChanges.value = false;
      let result = await clientsApi.editSupplier(route.params.id, {
        business_units: sections.map((unit) => unit.subunits).flat(),
      });
      supplierUpdate(result);
    }

    function formatSelectedBusinessUnits(ids) {
      businessUnits.value.forEach((unit) => {
        let subUnits = unit.subunits.filter((subunit) =>
          ids.includes(subunit.id)
        );

        if (subUnits.length)
          sections.push({
            unit: unit.id,
            subunits: subUnits.map((subunit) => subunit.id),
          });
      });
    }

    return {
      isLimited,
      ButtonType,
      // supplier,
      // businessUnits,
      isAnyChanges,
      isLoading,
      // selectedBusinessUnits,
      // UiSelect,
      // selected,
      // modelSelected,
      // multipleSelect,

      sections,
      unitItems,
      subUnitItems,
      updateUnit,
      nameSubunitById,

      removeSection,
      clearSubFunction,
      addSection,

      saveChanges,
      // currentBusinessUnits,
      // currentBusinessSubunits,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-section-btn {
  &.disabled {
    @apply pointer-events-none opacity-60;
  }
}

.function-menu__option {
  &.disabled {
    @apply pointer-events-none opacity-60;
  }
}
</style>
